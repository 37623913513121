import React, {FC} from 'react';
import {Box} from '@mui/material';
import Image from 'components/image/Image';
import DefaultPhoto from 'components/page/search/map/svg/item/defaultPhoto.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IPhoto {
    src?: string,
}

export const Photo: FC<IPhoto> = ({src}) => {
    const renderImage = () => {
        if (!src) {
            return (
                <Svg component={DefaultPhoto} width={'100%'} />
            );
        }
        return (
            <Image
                width={300}
                height={320}
                src={src}
                priority={true}
                alt={'Fotka č. 1'}
                style={{
                    width: '100%',
                    height: 'auto',
                }}
            />
        );
    };

    return (
        <Box height="100%">
            <Box width="100%" height="100%" position="relative">
                {renderImage()}
            </Box>
        </Box>
    );
};
